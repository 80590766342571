<template>
  <div class="chart-container h-unset">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.heading.services') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
        variant="background"
        class="chart-options"
        toggle-class="text-decoration-none rounded-10 px-4 py-4 "
        menu-class="rounded-8  dropdown-menu-show"
        no-caret
        dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>
        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
              @onUpdate="onDatePicked"
            />
          </div>
        </li>

      </b-dropdown>
    </div>
    <div class="chart__options">
      <div class="chart__options-tag"
           :class="item.type==selectedOption?'active':''"
           v-for="item in chartFilterOptions"
           :key="item.type"
           @click="changeOption(item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <v-chart class="chart" style="height: 600px;" :option="option" :autoresize="true" ref="echartTest"/>
  </div>
</template>

<script>


import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";

export default {
  components: {AppDatePicker},
  props: {
    data: Object
  },
  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },


    option() {
      return {
        tooltip: {
          trigger: 'axis',
          // formatter: "<span class='tool_tip_text'>" + ' {b0} <br/> {c0} ' + this.$t('common.order') + "</span>",
          className: "char-bar-tooltip3 ",
          position: 'left',

        },
        grid: {
          show:true,
          left: '16px',
          right: '16px',
          bottom: '16px',
          containLabel: true
        },
        legend: {
          top:"3%",
          left:"5%",
          show: true,
          align: 'right',
          itemGap:20,
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: this.data.colors,
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value'
        },
        dataset: {
          source: this.data.src
        },
        series: this.data.series,
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      }
    }
  },

  data() {
    return {
      filter: '',
      selectedOption: 'orders',
      chartFilterOptions: [

        {
          type: 'orders',
          name: this.$i18n.t('chart.options.orders_count')
        },
        {
          type: 'sales',
          name: this.$i18n.t('chart.options.sales')
        },
        {
          type: 'profit',
          name: this.$i18n.t('chart.options.profit')
        },
        {
          type: 'genders',
          name: this.$i18n.t('chart.options.genders')
        },
        {
          type: 'ages',
          name: this.$i18n.t('chart.options.ages')
        },

      ]
    }
  },
  methods: {
    onDatePicked(d) {
      this.$emit('changeFilter', {
        type: 'range',
        value:JSON.stringify(d)
      });
    },
    changeOption(type) {

      this.$refs.echartTest.clear();
      this.selectedOption = type
      this.$emit('changeOption', this.selectedOption);
    },
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>

</style>
