<template>

  <div class="chart_card">
    <div class="chart__heading mb-5">
      <div class="chart__heading-title">{{ $t('chart.heading.vendors') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
        variant="background"
        class="chart-options"
        toggle-class="text-decoration-none rounded-10 px-4 py-4 "
        menu-class="rounded-8  dropdown-menu-show"
        no-caret
        dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>

        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
              @onUpdate="onDatePicked"
            />
          </div>
        </li>

      </b-dropdown>
    </div>

    <div class="chart__options">
      <div class="chart__options-tag"
           :class="item.type==selectedOption?'active':''"
           v-for="item in chartFilterOptions"
           :key="item.type"
           @click="changeOption(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <div style="max-height:580px;overflow-y: auto;margin-top: 16px;">

      <template v-if="data.vendors.length>0">
        <chart-tile
          v-for="(item, index) in data.vendors"
          :key="index"
          :title="percentName"
          :color="vendorsColors[index]"
          :style="index===4?'margin-bottom: 0;':''"
          :total="data.total"
          :data="item"/>
      </template>

      <div style="margin-top: 100px;" v-else>
        <wameed-no-data/>
      </div>
    </div>
  </div>


</template>

<script>
import ChartTile from "@/views/pages/dashboard/components/chartTile";
import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";

export default {
  components: {AppDatePicker, ChartTile},
  props: {
    data: []
  },

  data() {
    return {
      percentName:this.$i18n.t('chart.type.order'),
      "vendorsColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#FED029"
      ],
      filter: '',
      selectedOption: 'orders_count',
      chartFilterOptions: [
        {
          type: 'orders_count',
          title:this.$i18n.t('chart.type.order'),
          name: this.$i18n.t('chart.options.orders_count')
        },

        {
          type: 'sales',
          title:this.$i18n.t('chart.type.rial'),
          name: this.$i18n.t('chart.options.sales')
        },
        {
          type: 'profit',
          title:this.$i18n.t('chart.type.rial'),
          name: this.$i18n.t('chart.options.profit')
        },

        {
          type: 'products',
          title:this.$i18n.t('chart.type.product'),
          name: this.$i18n.t('chart.options.products')
        },

        {
          type: 'reviews',
          title:this.$i18n.t('chart.type.review'),
          name: this.$i18n.t('chart.options.reviews')
        },



      ]
    }
  },
  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },
  },
  methods: {
    onDatePicked(d) {
      this.$emit('changeFilter', {
        type: 'range',
        value:JSON.stringify(d)
      });
    },
    changeOption(item) {
      this.selectedOption = item.type;
      this.percentName=item.title;
      this.$emit('changeOption', this.selectedOption);
    },
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>
